@use 'variables' as *;

.button__secondary {
  background: transparent;
  border: 1px solid $gray-90;
  border-radius: $corner;

  font-size: $base-size;
  font-weight: 600;
  padding: 8px 12px;
}

.button__primary {
  background: black;
  color: $gray-95;
  border: 1px solid $gray-90;
  border-radius: $corner;

  font-size: $base-size;
  font-weight: 600;
  padding: 8px 12px;
}