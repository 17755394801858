// breakpoints
$mobile: 768px;
$desktop: 1024px;


// colors
$green: #6aaa64;
$yellow: #c9b458;

$dark-green: #538d4e;
$dark-yellow: #b59f3b;

$gray-95: hsl(0, 0%, 95%);
$gray-90: hsl(0, 0%, 90%);
$gray-80: hsl(0, 0%, 80%);
$gray-60: hsl(0, 0%, 60%);
$gray-40: hsl(0, 0%, 40%);
$gray-20: hsl(0, 0%, 20%);
$gray-10: hsl(0, 0%, 10%);
$gray-5: hsl(0, 0%, 5%);


// text
$base-size: 14px;
$base-line-height: 1.5;


// borders
$corner: 8px;

// padding
$padding-xs: 2px;
$padding-sm: 4px;
$padding-md: 8px;
$padding-lg: 16px;
$padding-xl: 32px;